import { forwardRef } from 'react'

import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { Loader2 } from 'lucide-react'

import { cn } from '@design-system/lib/utils'

const buttonVariants = cva(
  'inline-grid flex-none items-center justify-center whitespace-nowrap rounded transition-all duration-100 disabled:pointer-events-none disabled:opacity-50 outline-none focus-visible:ring peer-focus:ring',
  {
    variants: {
      variant: {
        input:
          'rounded-none text-default w-full cursor-pointer items-center justify-items-start border-b border-l-0 border-r-0 border-t-0 px-xs py-xs target:border-primary hover:border-primary focus-visible:ring-0 focus-visible:border-primary border-input',
        primary:
          'border bg-primary text-inverse hover:bg-primary-hover active:drop-shadow-primary focus-visible:ring-primary',
        secondary:
          'border bg-secondary text-inverse hover:bg-secondary-hover active:drop-shadow-secondary focus-visible:ring-secondary',
        outline:
          'border bg-surface hover:bg-neutral-hover text-primary hover:text-primary-hover active:drop-shadow-outline focus-visible:ring-outline',
        'danger-outline':
          'border bg-surface hover:bg-error text-error hover:text-inverse active:drop-shadow-error focus-visible:ring-error',
        error:
          'bg-error text-inverse hover:bg-error-hover active:drop-shadow-error focus-visible:ring-error',
        link: 'border-b-2 border-b-transparent rounded-none focus-visible:ring-0 text-primary hover:text-primary-hover active:drop-shadow-primary !px-0 focus-visible:border-b-2 focus-visible:border-primary',
      },
      size: {
        small: 'min-h-8 px-l gap-x-xs',
        link: 'gap-x-s',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'small',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  /** error message from a form if relevant */
  error?: string | string[]
  isLoading?: boolean
  leftIcon?: React.ComponentType<{ size?: string | number; className?: string }>
  rightIcon?: React.ComponentType<{
    size?: string | number
    className?: string
  }>
  stretch?: boolean
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      leftIcon: _LeftIcon,
      rightIcon: RightIcon,
      isLoading,
      variant,
      size = 'small',
      error,
      stretch = true,
      asChild = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    const LeftIcon = isLoading ? Loader2 : _LeftIcon
    const iconGridOffset =
      LeftIcon && RightIcon
        ? 'grid-cols-[1rem_1fr_1rem]'
        : LeftIcon
          ? 'grid-cols-[1rem_1fr]'
          : RightIcon
            ? 'grid-cols-[1fr_1rem]'
            : undefined

    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size }),
          iconGridOffset,
          error && 'border-error text-error',
          !stretch && 'w-fit',
          className,
        )}
        ref={ref}
        {...props}
      >
        {asChild ? (
          children
        ) : (
          <>
            {LeftIcon && (
              <LeftIcon
                className={cn(
                  isLoading ? 'animate-spin' : undefined,
                  'shrink-0',
                )}
                size={16}
              />
            )}
            <span
              className={cn(
                size === 'small' && 'font-label-bold',
                variant === 'input' && 'font-label',
                variant === 'link' &&
                  'overflow-hidden text-ellipsis whitespace-nowrap font-label-bold',
              )}
              data-content
            >
              {children}
            </span>
            {RightIcon && (
              <RightIcon
                className={variant === 'input' ? 'opacity-50' : undefined}
                size={16}
              />
            )}
          </>
        )}
      </Comp>
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
