import * as React from 'react'

import { ChevronUp } from 'lucide-react'

import { cn } from '@design-system/lib/utils'

/**
 * sort direction will be 'asc' for ascending, 'desc' for descending, and 'none' for no sort applied
 */
type SortDirection = 'asc' | 'desc' | 'none'

interface SortButtonProps {
  children: React.ReactNode
  direction: SortDirection
  onClick: () => void
}

const SortButton = ({
  direction = 'none',
  onClick,
  children,
}: SortButtonProps) => (
  <button
    className="flex h-full w-full cursor-pointer flex-nowrap items-center justify-between gap-x-xs text-nowrap rounded-none px-m py-s"
    onClick={onClick}
    type="button"
  >
    {children}
    <ChevronUp
      className={cn(
        'size-4 min-h-4 min-w-4 transition-transform',
        direction === 'asc' && 'rotate-180 transform',
        direction === 'none' && 'opacity-0 hover:opacity-100',
      )}
    />
  </button>
)

/**
 * __Table__
 *
 * Basic Table component.
 *
 * @template Built from shadcn/ui Table component 'https://ui.shadcn.com/docs/components/table'
 *
 * @see: 'https://www.figma.com/file/qbcszGehTivEsGAzxFdrDN/OpTech-%2F-Design-System?type=design&node-id=203-2818&mode=design'
 *
 * For more advanced data tables (including pagination, sorting, filtering etc),
 * use the `@tanstack/react-table` library.
 *
 * @template 'https://ui.shadcn.com/docs/components/data-table'
 */
const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
  <div
    className={cn('relative w-full overflow-auto rounded border', className)}
  >
    <table className="w-full font-text-s" ref={ref} {...props} />
  </div>
))
Table.displayName = 'Table'

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    className={cn('bg-surface-card [&_tr]:border-b', className)}
    ref={ref}
    {...props}
  />
))
TableHeader.displayName = 'TableHeader'

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    className={cn(
      'border-b last:border-none [&_tr:last-child]:border-0',
      className,
    )}
    ref={ref}
    {...props}
  />
))
TableBody.displayName = 'TableBody'

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    className={cn(
      'border-t bg-neutral/50 font-medium [&>tr]:last:border-b-0',
      className,
    )}
    ref={ref}
    {...props}
  />
))
TableFooter.displayName = 'TableFooter'

type RowProps = React.HTMLAttributes<HTMLTableRowElement> & {
  isHeader?: boolean
}

const TableRow = React.forwardRef<HTMLTableRowElement, RowProps>(
  ({ className, isHeader = false, onClick, ...props }, ref) => (
    <tr
      className={cn(
        'border-b transition-colors hover:cursor-auto data-[state=selected]:bg-neutral',
        onClick && 'hover:cursor-pointer hover:bg-neutral-hover',
        isHeader && 'sticky top-0 z-10 border-b border-border bg-surface-card',
        className,
      )}
      onClick={onClick}
      ref={ref}
      {...props}
    />
  ),
)
TableRow.displayName = 'TableRow'

interface TableHeadProps extends React.ThHTMLAttributes<HTMLTableCellElement> {
  /**
   * If set, will render a sort button in the header cell
   */
  onSort?: (e?: any) => void
  /**
   * The current sort direction
   */
  sortDirection?: SortDirection
}

const TableHead = React.forwardRef<HTMLTableCellElement, TableHeadProps>(
  ({ className, onSort, sortDirection = 'none', children, ...props }, ref) => {
    const ariaSort = sortDirection
      ? sortDirection === 'asc'
        ? 'ascending'
        : 'descending'
      : undefined
    return (
      <th
        aria-sort={ariaSort}
        className={cn(
          'h-9 text-nowrap border-r text-left align-middle text-subtle font-text-s-bold last:border-r-0 [&:has([role=checkbox])]:pr-0',
          !onSort && 'px-m py-s',
          className,
        )}
        ref={ref}
        {...props}
      >
        {onSort ? (
          <SortButton direction={sortDirection} onClick={onSort}>
            {children}
          </SortButton>
        ) : (
          children
        )}
      </th>
    )
  },
)
TableHead.displayName = 'TableHead'

type CellProps = React.TdHTMLAttributes<HTMLTableCellElement> & {
  align?: 'left' | 'center' | 'right'
}

const TableCell = React.forwardRef<HTMLTableCellElement, CellProps>(
  ({ className, align, ...props }, ref) => (
    <td
      className={cn(
        'border-r p-3 align-middle last:border-r-0 [&:has([role=checkbox])]:pr-0',
        align === 'center' && 'text-center',
        align === 'right' && 'text-right',
        align === 'left' && 'text-left',
        className,
      )}
      ref={ref}
      {...props}
    />
  ),
)
TableCell.displayName = 'TableCell'

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    className={cn('mt-4 text-sm text-subtle', className)}
    ref={ref}
    {...props}
  />
))
TableCaption.displayName = 'TableCaption'

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
}
